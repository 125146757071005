@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rdw-fontfamily-wrapper, 
.rdw-option-wrapper[title="Italic"], 
.rdw-option-wrapper[title="Underline"], 
.rdw-option-wrapper[title="Strikethrough"],
.rdw-option-wrapper[title="Monospace"],
.rdw-option-wrapper[title="Superscript"],
.rdw-option-wrapper[title="Subscript"],
.rdw-option-wrapper[title="Unordered"],
.rdw-option-wrapper[title="Ordered"],
.rdw-option-wrapper[title="Indent"],
.rdw-option-wrapper[title="Outdent"],
.rdw-option-wrapper[title="Outdent"],
.rdw-block-wrapper,
.rdw-fontsize-wrapper,
.rdw-text-align-wrapper,
.rdw-colorpicker-wrapper,
.rdw-option-wrapper.rdw-option-disabled,
.rdw-embedded-wrapper,
.rdw-emoji-wrapper,
.rdw-image-wrapper,
.rdw-remove-wrapper
 {
  display: none !important;
}